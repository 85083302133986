


























































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { MessageDto, NotifyTemplateCreateOrUpdateDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "MessageList",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class MessageList extends Vue {
  tableItems: MessageDto[] = [];
  typeList: any = [];

  queryForm = {
    title: "",
    startDate: "",
    endDate: "",
  };
  detailId = 0;

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "MessageType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.message.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "messageCenterCreate" });
  }

  // 查看详情
  jumpDetail(index: number, row: MessageDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "messageCenterDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 编辑
  handleEdit(index: number, row: MessageDto) {
    this.$router.push({
      name: "messageCenterEdit",
      params: { id: row.id!.toString() },
    });
  }

  // 删除
  async handleDelete(index: number, row: MessageDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.message.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  jumpSend(row: MessageDto) {
    this.$router.push({
      name: "messageMaintain",
      query: { id: row.id!.toString() },
    });
  }
}
